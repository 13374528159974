import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

class ContactPage extends Component {

  render() {

    return (

      <Layout>
        <SEO title="Contact"/>
        <section className="hero is-medium has-absolute-navbar">
        <div className="hero-body">

          <div className="container">
            <div className="columns">
              <div className="column is-one-third">
                <h1>
                  Say hello
                </h1>
                <h4>If you have any questions, concerns, or stories to share with us, don’t hesitate to leave us a message or email us at hello@reasontosmile.com</h4>
              </div>
              <div className="column is-2" />
              <div className="column">
              <div id="contact-form-container">

                <ContactForm/>
                </div>
              </div>

            </div>

          </div>
          </div>

        </section>

      </Layout>
    )
  }
}

export default ContactPage
